<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-11-01 14:56:51
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-27 10:33:32
 * @FilePath: \sipingnongzhuan2\src\views\xsgz\index.vue
-->
<template>
  <div
    style="background:#fff;min-height:500px">
    <Czxx
      v-if="$route.path == '/czxx'">
    </Czxx>
    <List v-else>
    </List>
  </div>
</template>
<script>
import Czxx from './czxx.vue'
import List from '../../components/list/index.vue'
export default {
  components: { List, Czxx },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style>
</style>